import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image/withIEPolyfill';
import { Grid } from '@material-ui/core';
import Typography from '../../../../components/Typography';
import { strapiUrl } from '../../../../templates/constants';
import styles from './styles.module.sass';

export default ({ author }) => (
  <StaticQuery
    query={graphql`
      query {
        def: imageSharp (fluid: {
          originalName: { eq: "ContactPicture2.png" }
        }) {
          fluid(maxWidth: 160, maxHeight: 160, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    `}
    render={({ def }) => (
      <Grid item container xs={12} sm={12} md={12} lg={2} xl={2}
        className={styles.container}
        justify='center' alignContent='center' alignItems='center'>
        {author
        ? <>
            <Grid item container xs={12} xl={12} className={styles.photoContainer}
              justify='center'>
              {author.photo
              ? <img alt={`Blog post author`}
                  src={`${strapiUrl}${author.photo.url}`}
                  className={styles.image}/>
              : <Img imgStyle={{
                    objectFit: 'contain',
                    borderRadius: '50%' 
                  }}
                  objectFit='contain'      
                  className={styles.imageContainer} 
                  fluid={def.fluid}
                  alt="Contact person"/>}
            </Grid>
            <Grid item xs={12} xl={12}>
              <Typography align='center' variant='subtitle2' weight='bold'
                className={styles.name}>
                {author.name}
              </Typography>
            </Grid>
            <Grid item xs={12} xl={12}>
              <Typography align='center' color='dark' variant='subtitle2' weight='light'>
                {author.title}
              </Typography>
            </Grid>
          </>
        : null}
      </Grid>
    )}
  />
);